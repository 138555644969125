@import '../../../../assets/styles/variable';

.bp__toast {
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  left: 0 !important;
  letter-spacing: normal;
  line-height: normal;
  margin: auto;
  max-height: 200px;
  position: fixed;
  right: 0;
  top: 0;
  transform: none !important;
  width: 100%;
  z-index: 110;

  .Toastify {
    &__toast {
      min-height: auto;

      &--default {
        background-color: #000;
        color: $light-color1;

        .Toastify__toast-icon {
          margin-right: 0;
        }
      }

      &--info {
        background-color: $light-color2;
        color: $light-color1;
      }

      &--success {
        background-color: #4DC37D;
        color: $light-color1;
      }

      &--error {
        background-color: #FF5959;
        color: $light-color1;
      }
    }

    &__toast-body {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      margin: 0;
      max-height: 160px;
      overflow-y: auto;
      padding: 7px 13px;
    }

    &__toast-icon {
      display: inline-flex;
      margin-right: 10px;
      width: auto;

      .bp__icon {
        height: 18px;
        width: 18px;
      }
    }
  }
}

.Toastify__toast-container {
  z-index: 111 !important;
}

.mobile-view{
  .Toastify__toast-container{
    &.bp__toast {
      top: $header-height;
    }
  }
}

.source-itk, .source-itk-mobile{
  .Toastify__toast-container{
    &.bp__toast {
      top: $itk-header-height;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (width >= 576px) and (width <= 1200px) {
  /* Medium devices */
  .Toastify__toast-container {
    margin: unset;
    width: auto !important;
  }

  /* Small devices */
}

// Medium devices (tablets, 768px and up)
@media (width >= 768px) {
  /* Medium devices */
}

// Large devices (desktops, 992px and up)
@media (width >= 992px) {
  /* Large devices */
  .bp__toast {
    .Toastify {
      &__toast {
        &--error {
          width: 450px;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (width >= 1200px) {
  /* Extra large devices */
}