/* Font Sequance */
$fonts-seq: 'Visuelt', 'Roboto', 'Helvectica Neue', helvetica, arial, sans-serif;
$font-tstar-seq: 't-star', 'Visuelt', 'Roboto', 'Helvectica Neue', helvetica, arial, sans-serif;
$header-height: calc(64px + env(safe-area-inset-top));
$itk-header-height: 0; // 56px
$web-header-height: 97px;
$footer-height: 9vh;
$radio-checkbox-size: 20px;

/* Colors */
$color-blue: #01b5de;
$color-orange: #F37321;
$color-error: #F37320;
$button-bg-diabled: #6B7073;
$disabled: #6B7073;
$color-red-error: #DE3C41;

/* Light */
$light-color1: #fff;
$light-color2: #545456;
$light-color3: #6F6F8C;
$light-color4: #B2B5B6;
$light-color5: #989696;
$light-color6: #6B7073;
$light-color7: #555557;
$light-color8: #A9A9AA;
$light-color9: #99E1F2;
$light-bg1: #fff;
$light-bg2: #f6f7fb;
$light-bg3: #F8F8FF;
$light-bg4: #DFDFDF;
$light-border: #d8dcde;
$light-overlay: rgb(0 0 0 / 50%);

/* Dark */
$dark-color1: #fff;
$dark-color2: #A9A9AA;
$dark-color3: #000;
$dark-color4: #175F70;
$dark-bg1: #333;
$dark-bg2: #262627;
$dark-bg3: #A2A6A9;
$dark-border: #3B3E41;
$dark-overlay: rgb(0 0 0 / 50%);

/* Slider */
$slider-track: #E9E9E9;
$slider-track-disabled: #707070;
$slider-track-shadow: #00000029;
$slider-disable-track: #BABEC3;
$dark-slider-track: #545456;

/* ITK theme */
$itk-orange-light: #FFFBF3;
$itk-orange-medium: #FFB687;