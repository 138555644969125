@import './variable';
@import './animation';
@import './extend';
@import './mixin';
@import './fonts';
@import './htmlTags';
@import './actionButton';
@import './radioButtonCheckbox';
@import './atomDesign';

a{
  @extend %color-blue;
}

div[role='button'],
span[role='button'],
a[role='button'] {
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

div[draggable="false"]{
  left: auto !important;  
  top: auto !important;  
}

[type='button'],
[role='button'] {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

section{
  &.bp{
    &__card-view{
      background-color: $light-bg1;
      border-radius: 10px;
      box-shadow: 1px 2px 10px 0 rgb(63 67 71 / 8%);
      margin: 12px 0 0;
      @include user-select;
      
      &:first-child{
        margin-top: 0;
      }

      .bp__card-view{
        &--details{
          display: none;
        }
      }
      
      &--open{
        .bp__card-view{
          &--details{
            display: block;
          }
        }
      }
    }
  }

  .bp{
    &__card-view{
      &__wrapper{
        padding: 16px;
      }

      &--title{
        color: $light-color2;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }

      &--desc{
        color: $light-color3;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 10px;
      }

      &--sub-desc{
        color: $light-color4;
        font-size: 12px;
        line-height: 18px;
        margin-top: 10px;
      }

      &--value{
        font-weight: 500;
        @extend %color-blue;
      }

      &--options{
        padding: 8px 15px 0;
      }
    }
  }
}

svg{
  display: block;
}

.bp__status-text--enabled{
  color: #F37320;
  font-weight: 500;
}

.bp__status-text--disabled{
  color: #a2abb3;
  font-weight: 500;
}

.bp__status-text--pending{
  color: #c6c8ca;
}

.bp__status-text--failed{
  color: #FF5A5F;
}

.bp__link{
  color: $color-blue;
}

.bp__text--center{
  align-items: center;
  color: $light-color2;
  display: flex;
  min-height: 58%;
  justify-content: center;
}

.bp__shimmer{
  animation: shimmer 2s infinite;
  background-repeat: no-repeat;
  color: grey;
  display:inline-block;
  font-size: 50px;
  height: inherit;
  mask:linear-gradient(-60deg,#000 30%,#0007,#000 70%) right/300% 100%;
  width: inherit;
}

/* *********************** *********************** *********************** *
*  Alert :: Popup
* *********************** *********************** *********************** */
.bp{
  &__alert-popup{
    .bp__popup__wrapper{
      padding: 40px 20px 25px;
    }

    .bp__icon{
      margin-top: 20px;

      &.icon-alert{
        height: 50px;
        width: 50px;

        svg{
          margin: auto;
        }
      }

      &.icon-info-v2{
        height: 50px;
        width: 50px;

        svg{
          margin: auto;
        }
      }
    }
  }
}

.bp__dialog{
  .bp{
    &__alert-popup{
      .e_dialog__wrapper{
        max-width: 450px;
      }
      
      .e_dialog__content{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;

        &:first-of-type{
          margin-top: 25px;
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme{
  section{
    &.bp{
      &__card-view{
        background-color: $dark-bg1;
      }
    }

    .bp{
      &__card-view{
        &--title{
          color: $dark-color1;
        }

        &--desc{
          color: $dark-color2;
        }

        &--sub-desc{
          color: $dark-color2;
        }
      }
    }
  }
}

/****************************************************************************
Web view
****************************************************************************/

.web-view {
  #enho-battery{
    section{
      &.bp{
        &__card-view{
          border-radius: 0;
        }
      }

      .bp{
        &__card-view{
          &__wrapper{
            border-radius: 0;
            padding: 30px;
          }

          &--title{
            font-size: 20px;
          }

          &--desc{
            font-size: 15px;
            line-height: 20px;
          }

          &--sub-desc{
            font-size: 15px;
            line-height: 20px;
          }
        }
      }
    }  
  }

  #enho-profile{
    section{
      &.bp{
        &__card-view{
          border-radius: 8px;
          width: 100%;
        }
      }

      .bp{
        &__card-view{
          &__wrapper{
            padding: 0;
          }
        }
      }
    }
  }
}

/****************************************************************
Orange theme
****************************************************************/

.orange-theme{
  a{
    @extend %color-orange;
  }

  section{
    .bp{
      &__card-view{
        &--value{
          color: $color-orange;
        }
      }
    }
  }

  .bp__link{
    color: $color-orange;

    span{
      svg g{
        fill: $color-orange;

        path{
          stroke: $color-orange;
        }
      }
    }
  }
}